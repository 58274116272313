var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('confirm-disable',{ref:"disableModal",attrs:{"msg":_vm.disableMsg},on:{"on-submit":_vm.switchFaqStatus,"on-hide":_vm.resetStatus}}),_c('confirm-delete',{ref:"modal",attrs:{"msg":_vm.deleteMsg,"title":_vm.titleMsg},on:{"on-submit":_vm.deleteFaq}}),_c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',[(
              _vm.checkLoginRole() === _vm.RoleEnum.SuperAdmin
                ? true
                : _vm.checkAccess.Add
            )?_c('div',{staticClass:"d-flex align-items-end justify-content-end flex-wrap"},[_c('b-col',{staticClass:"mb-2",attrs:{"md":"4"}},[_c('label',[_vm._v("Status")]),_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusFilter,"reduce":function (val) { return val.value; },"clearable":true},model:{value:(_vm.status_filter),callback:function ($$v) {_vm.status_filter=$$v},expression:"status_filter"}})],1),_c('b-col',{staticClass:"mb-2",attrs:{"md":"8"}},[_c('label',[_vm._v("Category")]),_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.categoryFilter,"reduce":function (val) { return val.value; },"clearable":true},model:{value:(_vm.category_filter),callback:function ($$v) {_vm.category_filter=$$v},expression:"category_filter"}})],1)],1):_vm._e()])],1)],1)]),_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"4","lg":"4","sm":"12"}},[_c('label',[_vm._v("Show")]),_c('v-select',{staticClass:"per-page-selector d-inline-block mx-50",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('label',[_vm._v("entries")])],1),_c('b-col',{attrs:{"cols":"12","md":"8","lg":"8","sm":"12"}},[(
              _vm.checkLoginRole() === _vm.RoleEnum.SuperAdmin
                ? true
                : _vm.checkAccess.Add
            )?_c('div',{staticClass:"d-flex align-items-end justify-content-end flex-wrap"},[_c('b-button',{staticClass:"mb-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$router.push('/faq/category/list')}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Categories")])]),_c('b-button',{staticClass:"ml-2 mb-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$router.push('add')}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Add FAQ")])])],1):_vm._e()])],1)],1),(!_vm.contentLoading)?_c('b-table',{ref:"refUserListTable",staticClass:"position-relative",attrs:{"items":_vm.items,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"No matching records found","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"cell(category)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.category.name)+" ")]}},{key:"cell(question)",fn:function(data){return [_c('div',[(!data.item.isRead)?_c('span',{domProps:{"innerHTML":_vm._s(data.item.faqLanguage[0].question.slice(0, 200))}}):_vm._e(),(
              !data.item.isRead &&
                data.item.faqLanguage[0].question.length >= 180
            )?_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.activateReadMore(data.item.id)}}},[_vm._v(" Read more... ")]):_vm._e(),(data.item.isRead)?_c('span',{domProps:{"innerHTML":_vm._s(data.item.faqLanguage[0].question)}}):_vm._e(),(
              data.item.isRead &&
                data.item.faqLanguage[0].question.length >= 180
            )?_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.activateReadLess(data.item.id)}}},[_vm._v(" Read less... ")]):_vm._e()])]}},{key:"cell(answer)",fn:function(data){return [(!data.item.isReadAnswer)?_c('span',{domProps:{"innerHTML":_vm._s(data.item.faqLanguage[0].answer.slice(0, 200))}}):_vm._e(),(
            !data.item.isReadAnswer &&
              data.item.faqLanguage[0].answer.length >= 180
          )?_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.activateReadMoreAnswer(data.item.id)}}},[_vm._v(" Read more... ")]):_vm._e(),(data.item.isReadAnswer)?_c('span',{domProps:{"innerHTML":_vm._s(data.item.faqLanguage[0].answer)}}):_vm._e(),(
            data.item.isReadAnswer &&
              data.item.faqLanguage[0].answer.length >= 180
          )?_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.activateReadLessAnswer(data.item.id)}}},[_vm._v(" Read less... ")]):_vm._e()]}},{key:"cell(status)",fn:function(data){return [_c('div',[_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"name":"check-button","switch":""},on:{"change":function($event){return _vm.openDisableModal(data.item.id, data.item.isActive)}},model:{value:(data.item.isActive),callback:function ($$v) {_vm.$set(data.item, "isActive", $$v)},expression:"data.item.isActive"}})],1)]}},{key:"cell(actions)",fn:function(data){return [(_vm.checkAccess.Edit || _vm.checkAccess.delete)?_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(
              _vm.checkLoginRole() === _vm.RoleEnum.SuperAdmin
                ? true
                : _vm.checkAccess.Edit
            )?_c('b-dropdown-item',{attrs:{"to":{ name: 'faq-edit', params: { id: data.item.id } }}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Edit")])],1):_vm._e(),(
              _vm.checkLoginRole() === _vm.RoleEnum.SuperAdmin
                ? true
                : _vm.checkAccess.delete
            )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.openModal(data.item.id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Delete")])],1):_vm._e()],1):_vm._e()]}}],null,false,1630465117)},[_vm._v(". ")]):_vm._e(),(_vm.contentLoading)?_c('Loader'):_vm._e(),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Showing "+_vm._s(_vm.totalFaqs >= 1 ? _vm.startIndex + 1 : _vm.startIndex)+" to "+_vm._s(_vm.endIndex > _vm.totalFaqs ? _vm.totalFaqs : _vm.endIndex)+" of "+_vm._s(_vm.totalFaqs)+" entries")])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalFaqs,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},on:{"change":_vm.pageChange},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }