<template>
  <div>
    <confirm-disable
      ref="disableModal"
      :msg="disableMsg"
      @on-submit="switchFaqStatus"
      @on-hide="resetStatus"
    />
    <confirm-delete
      ref="modal"
      :msg="deleteMsg"
      :title="titleMsg"
      @on-submit="deleteFaq"
    />

    <b-card no-body>
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <b-col>
            <div
              v-if="
                checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.Add
              "
              class="d-flex align-items-end justify-content-end flex-wrap"
            >
              <b-col md="4" class="mb-2">
                <label>Status</label>
                <v-select
                  v-model="status_filter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusFilter"
                  class="w-100"
                  :reduce="val => val.value"
                  :clearable="true"
                />
              </b-col>

              <b-col md="8" class="mb-2">
                <label>Category</label>
                <v-select
                  v-model="category_filter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="categoryFilter"
                  class="w-100"
                  :reduce="val => val.value"
                  :clearable="true"
                />
              </b-col>
            </div>
          </b-col>
          </b-row>
        </div>
      </b-card>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            lg="4"
            sm="12"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <b-col cols="12" md="8" lg="8" sm="12">
            <div
              v-if="
                checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.Add
              "
              class="d-flex align-items-end justify-content-end flex-wrap"
            >
              <b-button
                class="mb-2"
                variant="primary"
                @click="$router.push('/faq/category/list')"
              >
                <span class="text-nowrap">Categories</span>
              </b-button>

              <b-button class="ml-2 mb-2" variant="primary" @click="$router.push('add')">
                <span class="text-nowrap">Add FAQ</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        v-if="!contentLoading"
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(category)="data">
          <!--eslint-disable-next-line vue/no-v-html -->
          {{ data.item.category.name }}
        </template>

        <!-- Column: Question -->.
        <template #cell(question)="data">
          <div>
            <!--eslint-disable-next-line vue/no-v-html -->
            <span
              v-if="!data.item.isRead"
              v-html="data.item.faqLanguage[0].question.slice(0, 200)"
            />
            <a
              v-if="
                !data.item.isRead &&
                  data.item.faqLanguage[0].question.length >= 180
              "
              href="#"
              @click="activateReadMore(data.item.id)"
            >
              Read more...
            </a>
            <!--eslint-disable-next-line vue/no-v-html -->
            <span
              v-if="data.item.isRead"
              v-html="data.item.faqLanguage[0].question"
            />
            <a
              v-if="
                data.item.isRead &&
                  data.item.faqLanguage[0].question.length >= 180
              "
              class=""
              href="#"
              @click="activateReadLess(data.item.id)"
            >
              Read less...
            </a>
          </div>
        </template>

        <!-- Column: Answer -->
        <template #cell(answer)="data">
          <!--eslint-disable-next-line vue/no-v-html -->
          <span
            v-if="!data.item.isReadAnswer"
            v-html="data.item.faqLanguage[0].answer.slice(0, 200)"
          />
          <a
            v-if="
              !data.item.isReadAnswer &&
                data.item.faqLanguage[0].answer.length >= 180
            "
            href="#"
            @click="activateReadMoreAnswer(data.item.id)"
          >
            Read more...
          </a>
          <!--eslint-disable-next-line vue/no-v-html -->
          <span
            v-if="data.item.isReadAnswer"
            v-html="data.item.faqLanguage[0].answer"
          />
          <a
            v-if="
              data.item.isReadAnswer &&
                data.item.faqLanguage[0].answer.length >= 180
            "
            href="#"
            @click="activateReadLessAnswer(data.item.id)"
          >
            Read less...
          </a>
        </template>

        <!-- Column Status -->
        <template #cell(status)="data">
          <div>
            <b-form-checkbox
              v-model="data.item.isActive"
              class="custom-control-primary"
              name="check-button"
              switch
              @change="openDisableModal(data.item.id, data.item.isActive)"
            />
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            v-if="checkAccess.Edit || checkAccess.delete"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="
                checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.Edit
              "
              :to="{ name: 'faq-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="
                checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.delete
              "
              @click="openModal(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <Loader v-if="contentLoading" />
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ totalFaqs >= 1 ? startIndex + 1 : startIndex }} to
              {{ endIndex > totalFaqs ? totalFaqs : endIndex }} of
              {{ totalFaqs }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalFaqs"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="pageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import {
  resolveUserRoleIcon,
  resolveUserRoleVariant,
  resolveUserStatusVariant,
  resolveUserRoleName,
  resolveUserStatusName,
  perPageOptions,
  perPage,
  currentPage,
  RoleEnum,
  statusFilter,
  resConditionCheck,
  deleteConfirmMsg
} from "@/helpers/constant";
// import accessRightCheck from '@/helpers/accessRightCheck'
import checkLoginRole from "@/helpers/checkLoginRole";
import faqService from "@/services/faq/faq.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import errorResponseHandler from "@/services/errorHandler";
import accessRightCheck from "@/helpers/accessRightCheck";
import Loader from "@/layouts/skeloton-loader/Loader.vue";
import ConfirmDelete from "../../confirm-delete/ConfirmDelete.vue";
import ConfirmDisable from "../../confirm-disable/ConfirmDisable.vue";

// import { mapState } from 'vuex'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormCheckbox,
    ConfirmDisable,
    ConfirmDelete,
    Loader
  },

  data() {
    return {
      contentLoading: true,
      searchQuery: "",
      sortBy: "",
      totalFaqs: 0,
      isSortDirDesc: true,
      avatarText,
      items: [],
      startIndex: 0,
      endIndex: 0,

      tableColumns: [
        { key: "category", sortable: true },
        { key: "question" },
        { key: "answer" },
        { key: "status" },
        { key: "actions" }
      ],
      currentPage,
      perPage,
      perPageOptions,
      resolveUserRoleIcon,
      resolveUserRoleVariant,
      resolveUserStatusVariant,
      resolveUserRoleName,
      resolveUserStatusName,
      resConditionCheck,
      statusFilter,
      deleteMsg: "",
      titleMsg: "",
      userId: "",
      toggleModal: false,
      filterList: [],
      first_name: "",
      last_name: "",
      email: "",
      signinAsAdmin: false,
      status_filter: null,
      checkAccess: {},
      checkLoginRole,
      RoleEnum,
      disableMsg: "",
      faqId: "",
      deleteFaqId: "",
      status2: "",
      category_filter: null,
      categoryFilter: []
    };
  },

  watch: {
    currentPage: {
      handler() {
        this.getFaqListing();
        this.getCategoryListing();
      }
    },
    perPage: {
      handler() {
        this.currentPage = 1;
        this.getFaqListing();
        this.getCategoryListing();
      }
    },
    sortBy: {
      handler(newValue) {
        this.sortBy = newValue === "user" ? "firstName" : newValue;
        this.getFaqListing();
        this.getCategoryListing();
      }
    },
    isSortDirDesc: {
      handler() {
        this.getFaqListing();
        this.getCategoryListing();
      }
    },

    status_filter: {
      handler() {
        this.currentPage = 1;
        this.getFaqListing();
        this.getCategoryListing();
      }
    },

    category_filter: {
      handler() {
        this.currentPage = 1;
        this.getFaqListing();
        this.getCategoryListing();
      }
    },
  },

  mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    if (
      this.checkLoginRole() !== RoleEnum.SuperAdmin
      && !this.checkAccess.Edit
      && !this.checkAccess.delete
    ) {
      this.tableColumns = [
        { key: "category", sortable: true },
        { key: "question" },
        { key: "answer" },
        { key: "status" }
      ];
    }
    this.getFaqListing();
    this.getCategoryListing();
  },

  methods: {
    pageChange() {
      window.scrollTo(0, 0);
    },

    getFaqListing() {
      this.contentLoading = true
      const payload = {
        no_of_result: this.perPage,
        page: this.currentPage,
        order: this.isSortDirDesc === true ? "DESC" : "ASC",
        order_by: this.sortBy === 'category' ? 'name' : '',
        status_filter: this.status_filter,
        category_id: this.category_filter
      };
      faqService
        .getFaqListing(payload)
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.contentLoading = false;
            this.items = res.data.data.map(e => ({
              ...e,
              isRead: false,
              isReadAnswer: false
            }));
            this.totalFaqs = res.data.count;
            this.startIndex = this.currentPage * this.perPage - this.perPage;
            this.endIndex = this.startIndex + this.perPage;
          }
        })
        .catch(error => {
          const errorData = errorResponseHandler(error);
          this.contentLoading = false;
          if (error.response.status === 404) {
            this.items = [];
            this.totalFaqs = 0;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          }
        });
    },

    openDisableModal(id, status) {
      this.faqId = id;
      this.status2 = !status;
      this.$refs.disableModal.show();
      this.disableMsg = !status
        ? `Are you sure you want to deactivate this FAQ ?`
        : `Are you sure you want to activate this FAQ ?`;
    },

    resetStatus() {
      const foundIdex = this.items.findIndex(item => item.id === this.faqId);
      this.$set(this.items[foundIdex], "isActive", this.status2);
    },

    openModal(id) {
      this.titleMsg = deleteConfirmMsg;
      this.deleteMsg = `Are you sure you want to delete this FAQ ?`;
      this.deleteFaqId = id;
      this.$refs.modal.show();
    },

    deleteFaq(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.modal.loading = true;
      if (this.deleteFaqId) {
        faqService
          .deleteFaq(this.deleteFaqId)
          .then(res => {
            if (resConditionCheck(res.status)) {
              this.$refs.modal.loading = false;
              this.$refs.modal.toggleModal = false;
              this.items = this.items.filter(
                item => item.id !== this.deleteFaqId
              );
              if (this.items.length === 0 && this.currentPage !== 1) {
                this.currentPage -= 1;
                this.getFaqListing();
              } else {
                this.getFaqListing();
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            this.$refs.modal.loading = false;
            this.$refs.modal.toggleModal = false;
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      }
    },

    activateReadMore(id) {
      const item = this.items.find(e => e.id === id);
      item.isRead = true;
    },

    activateReadLess(id) {
      const item = this.items.find(e => e.id === id);
      item.isRead = false;
    },

    activateReadMoreAnswer(id) {
      const item = this.items.find(e => e.id === id);
      item.isReadAnswer = true;
    },

    activateReadLessAnswer(id) {
      const item = this.items.find(e => e.id === id);
      item.isReadAnswer = false;
    },
    switchFaqStatus(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.disableModal.loading = true;
      if (this.faqId) {
        faqService
          .switchFaqStatus(this.faqId)
          .then(res => {
            this.$refs.disableModal.loading = false;
            this.$refs.disableModal.toggleModal = false;
            if (resConditionCheck(res.status)) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            this.$refs.disableModal.loading = false;
            this.$refs.disableModal.toggleModal = false;
            const errorData = errorResponseHandler(error);
            const foundIdex = this.items.findIndex(
              item => item.id === this.faqId
            );
            this.$set(this.items[foundIdex], "isActive", this.status2);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      }
    }, 

    getCategoryListing() {
      faqService
        .getCategoryListing()
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.categoryFilter = [];
            res.data.data.map((e) => {
              let obj = {
                label: e.name,
                value: e.id
              };
              this.categoryFilter.push(obj);
            });
          }
        })
        .catch(error => {
          const errorData = errorResponseHandler(error);
          if (error.response.status === 404) {
            this.items = [];
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          }
        });
    },
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
